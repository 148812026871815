import React, { FC } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../helpers"
import { Link } from "react-router-dom"
import { Field, Form, Formik } from "formik"
import MultiSelect from "../../../../app/modules/auth/components/registration/components/create-registration-stepper/steps/MultiSelect"

const SubTaskCreationDrawer: FC = () => {
  const options = [
    { value: 'User 1', label: 'user 1' },
    { value: 'User 2', label: 'user 2' },
    { value: 'User 3', label: 'user 3' },
    { value: 'User 4', label: 'user 4' },
    { value: 'User 5', label: 'user 5' },
    { value: 'User 6', label: 'user 6' },
    { value: 'User 7', label: 'user 7' },
    { value: 'User 8', label: 'user 8' },
    { value: 'User 9', label: 'user 9' },
    { value: 'User 10', label: 'user 10' },
    // Add more options as needed
  ];
  return (
    <>
      <div
        id='ewf_subTask_modal'
        className='bg-body shadow-lg'
        data-kt-drawer='true'
        data-kt-drawer-name='subTaskModal'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'550px', 'lg': '700px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#ewf_subTask_modal_toggle'
        data-kt-drawer-close='#ewf_subtaskmodal_close'
      >
        <div className='card rounded-0 w-100 h-100'>
          <div className='card-header' id='kt_activities_header'>
            <h3 className='card-title fw-bolder text-dark'>Create Ticket/Group</h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='ewf_subtaskmodal_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
          </div>
          <div className='card-body position-relative' id='kt_activities_body'>
            <div
              id='kt_activities_scroll'
              className='position-relative me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_activities_body'
              data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='timeline'>
                <Formik initialValues={{}} onSubmit={(values) => console.log(values)}>
                  {({ values }) => (
                    <Form>
                      <div className="row mb-12">
                        <div className="col-lg-6 mb-4">
                          <label className='form-label fw-bold'>Ticket Title</label>
                          <Field placeholder='Ticket Title' type='text' name='ticketTitle' className='form-control' />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label className='form-label fw-bold'>Due Date</label>
                          <Field type='date' name='dueDate' className='form-control' />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label className='form-label fw-bold'>Assignees</label>
                          <Field
                            name='assignees'
                            id='assignees'
                            placeholder='Assignees'
                            component={MultiSelect}
                            isMulti={true}
                            options={options} />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label className='form-label fw-bold'>Creator</label>
                          <Field
                            name='creators'
                            id='creators'
                            placeholder='Creators'
                            component={MultiSelect}
                            isMulti={false}
                            options={options} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="mb-4">Uploads</h4>
                          <div className="bg-light p-6 mb-12 rounded d-flex align-items-center">
                            <img src={toAbsoluteUrl("/media/svg/files/blank-image-dark.svg")} width="150" alt="File Upload" className="me-4" />
                            <div className="position-relative h-150px w-150px bg-gray-300i d-flex justify-content-center align-items-center">
                              <img src={toAbsoluteUrl("/media/svg/files/upload.svg")} width="100" alt="File Upload" />
                              <input type="file" className="position-absolute h-100 w-100 opacity-0 cursor-pointer" style={{ left: 0 }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-12">
                          <h4 className="mb-4">Description</h4>
                          <textarea className="form-control" rows={5} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <button className="btn btn-info"><i className="fa fa-plus"></i> Create</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <Link to='/crafted/pages/profile' className='btn btn-bg-body text-primary'>
              View All Activities
              <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 svg-icon-primary'
              />
            </Link>
          </div> */}
        </div>
      </div>
    </>
  )
}

export { SubTaskCreationDrawer }