import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TaskGroupProps {
  title: string;
  phaseName: string,
  author: string;
  timeCreated: string;
  deadline: string;
  tags: string;
  tasks?: any[];
}

interface TaskProps {
  taskGroup: TaskGroupProps[];
}

const initialState: TaskProps = {
  taskGroup: [],
};

const taskGroupSlice = createSlice({
  name: 'taskGroup',
  initialState,
  reducers: {
    addTaskGroup: (state, action: PayloadAction<TaskGroupProps>) => {
      state.taskGroup.push(action.payload);
    },
    removeTaskGroup: (state, action: PayloadAction<number>) => {
      state.taskGroup.splice(action.payload, 1);
    },
    updateTaskGroup: (state, action: PayloadAction<{ index: number; taskGroup: TaskGroupProps }>) => {
      state.taskGroup[action.payload.index] = action.payload.taskGroup;
    },
  },
});

export const {
  addTaskGroup,
  removeTaskGroup,
  updateTaskGroup
} = taskGroupSlice.actions;

export default taskGroupSlice.reducer;
