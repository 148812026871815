import { combineReducers } from '@reduxjs/toolkit';
import timelineSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/Timeline/timelineSlice'
import authSlice from '../app/modules/redux/auth/authSlice';
import meetingMinutesSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/Coordination/MeetingMinutes/meetingMinutesSlice';
import requestForInformationSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/Coordination/RequestForInformation/requestForInformationSlice';
import submittalSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/Coordination/Submittal/submittalSlice';
import siteVisitsSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/Coordination/SiteVisits/siteVisitsSlice';
import createProjectSlice from '../app/modules/eventWorkflow/projects/redux/projectBoard/createProjectSlice';
import taskGroupSlice from '../_metronic/partials/layout/taskGroupCreation-drawer/taskGroupSlice';

const rootReducer = combineReducers({
  timeline: timelineSlice,
  auth: authSlice,
  meetingMins: meetingMinutesSlice,
  requestForInformation: requestForInformationSlice,
  submittal: submittalSlice,
  siteVisits: siteVisitsSlice,
  createProject: createProjectSlice,
  taskGroup: taskGroupSlice
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
