/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'

const Item1: FC = () => {
  return (
    <>
      <div className='timeline-item'>
        <div className='timeline-line w-40px'></div>

        <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
          <div className='symbol-label bg-light'>
            <KTSVG
              path='/media/icons/duotune/communication/com003.svg'
              className='svg-icon-2 svg-icon-gray-500'
            />
          </div>
        </div>

        <div className='timeline-content mb-10 mt-n1'>
          <div className='pe-3 mb-5 d-flex justify-content-between align-items-center'>
            <div>
              <div className='fs-5 fw-bold mb-2'>
                There are 2 new tasks
              </div>

              <div className='d-flex align-items-center mt-1 fs-6'>
                <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

                <div
                  className='symbol symbol-circle symbol-25px'
                  data-bs-toggle='tooltip'
                  data-bs-boundary='window'
                  data-bs-placement='top'
                  title='Nina Nilson'
                >
                  <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
                </div>
                <span className="badge badge-danger ms-3">Deadline: 12/3/23</span>
              </div>
            </div>
            <div>
              <button className="btn btn-sm btn-info" id='ewf_subTask_modal_toggle'>
                <i className='fa fa-plus'></i> Create Sub Task
              </button>
            </div>
          </div>

          <div className="py-5">
            <table className="table table-row-dashed table-row-gray-300 gy-3">
              <tbody>
                <tr>
                  <td><a href='#' className='fs-5 text-dark text-hover-primary fw-bold'>
                    Meeting with customer
                  </a></td>
                  <td><div className='pe-2'>
                    <span className='badge badge-primary'>Application Design</span>
                  </div></td>
                  <td>
                    <div className='symbol-group symbol-hover pe-2'>
                      <div className='symbol symbol-circle symbol-25px'>
                        <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='img' />
                      </div>

                      <div className='symbol symbol-circle symbol-25px'>
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
                      </div>

                      <div className='symbol symbol-circle symbol-25px'>
                        <div className='symbol-label fs-8 fw-bold bg-primary text-inverse-primary'>A</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='pe-2'>
                      <span className='badge badge-light-primary'>In Progress</span>
                    </div>
                  </td>
                  <td>
                    <Link to={'/projects/PIM/project/overview/days/day/phases/phase/workspace/ticket/23'}>
                      <button className='btn btn-sm btn-light-primary'>
                        <i className='fa fa-eye'></i>
                      </button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className='d-flex align-items-center'>
                    <a href='#' className='fs-5 text-dark text-hover-primary fw-bold'>
                      Meeting with customer
                    </a>
                  </td>
                  <td>
                    <div className='pe-2'>
                      <span className='badge badge-primary'>Application Design</span>
                    </div>
                  </td>
                  <td>
                    <div className='symbol-group symbol-hover pe-2'>
                      <div className='symbol symbol-circle symbol-25px'>
                        <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='img' />
                      </div>

                      <div className='symbol symbol-circle symbol-25px'>
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
                      </div>

                      <div className='symbol symbol-circle symbol-25px'>
                        <div className='symbol-label fs-8 fw-bold bg-primary text-inverse-primary'>A</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='pe-2'>
                      <span className='badge badge-light-primary'>In Progress</span>
                    </div>
                  </td>
                  <td>
                    <Link to={'/projects/PIM/project/overview/days/day/phases/phase/workspace/ticket/23'}>
                      <button className='btn btn-sm btn-light-primary'>
                        <i className='fa fa-eye'></i>
                      </button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export { Item1 }
