import {AuthModel} from './_models'
import {requestRefreshToken} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers['content-type'] = 'application/json'
  axios.defaults.withCredentials = true
  axios.defaults.origin = true

  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      // Access token has expired.
      const originalRequest = error?.config

      if (error?.response?.status === 401 && !originalRequest?.sent) {
        originalRequest.sent = true

        console.log('Token need to be refreshed!')

        // Refresh Token to get a new AccessToken.
        const auth = getAuth()
        // if (auth && auth.accessToken) {
        if (auth) {
          const {data: newAuth} = await requestRefreshToken(auth.accessToken)

          setAuth(newAuth)

          // originalRequest.headers['Authorization'] = `Bearer ${newAuth.accessToken}`
          originalRequest.headers.Authorization = `Bearer ${newAuth.accessToken}`
        }

        return originalRequest
      }

      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
