import { call, put } from "redux-saga/effects";
import { CurrentUserSuccess } from "../../app/modules/redux/auth/authSlice";
import { getUserProfileByToken } from "../../app/modules/auth/core/_requests";

export function* getUserByTokenSaga(accessToken: any): Generator<any, void, string> {
  try {
    const userData = yield call(getUserProfileByToken, accessToken.payload ? {"accessToken": accessToken.payload} : accessToken)
    yield put(CurrentUserSuccess(userData))
  } catch (error) {
    console.error(error)
  }
}