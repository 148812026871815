import {PayloadAction, createSlice} from '@reduxjs/toolkit'

export interface DaysFieldProps {
  id: string
  eventDate: string
  eventTime: string
  eventDuration: string
  guestCount: string
  projectBudget: string
  currency: string
}

export interface ClientProps {
  id: string
  firstName: string
  lastName: string
  email: string
  contact: string
  role?: string
}

export interface CheckVenueBookingProps {
  venueName: string
  venueAddress: string
}

export interface ProjectProps {
  id: string
  isMultiDate: false
  howManyDays: 1
  daysField: DaysFieldProps[]
  checkVenueBooking: CheckVenueBookingProps[]
  projectName: string
  projectCategory: string
  projectTemplate: string
  clients: ClientProps[]
  planningPartner: ClientProps[]
}

interface ProjectState {
  projects: ProjectProps[]
}

export const initialProjectCreationState: ProjectState = {
  projects: [],
}

const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: initialProjectCreationState,
  reducers: {
    addProject: (state, action: PayloadAction<ProjectProps>) => {
      state.projects.push(action.payload)
    },
    updateProject: (state, action: PayloadAction<{id: string; updatedProject: ProjectProps}>) => {
      const {id, updatedProject} = action.payload
      const index = state.projects.findIndex((project) => project.id === id)
      if (index !== -1) {
        state.projects[index] = updatedProject
      }
    },
    removeProject: (state, action: PayloadAction<string>) => {
      const id = action.payload
      state.projects = state.projects.filter((project) => project.id !== id)
    },
  },
})

export const {addProject, updateProject, removeProject} = createProjectSlice.actions
export default createProjectSlice.reducer
