import {call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  loginRequest,
  logoutRequest,
  currentUserRequest,
} from './authSlice'
import {logout} from '../../../../setup/axios/axios'
import {userLogin} from '../../auth/core/_requests'
import store from '../../../../setup/store'
import {getUserByTokenSaga} from '../../../../setup/saga/globalSagas'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

/**
 * Generator function for saga, performing login request and
 * getting loggedin user data once successfully login
 * @param action any
 */
function* login(action: any): Generator<any, void, string> {
  try {
    // Call the authentication API
    const {username, password} = action.payload
    const token: any = yield call(userLogin, username, password)
    // Save token in localStorage
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(token))
    // Dispatch a success action with the token
    yield put(loginSuccess(token.accessToken))
    // Call getUserByTokenSaga with the token
    yield call(getUserByTokenSaga, store.getState().auth.token)
  } catch (error) {
    // Dispatch a failure action with the error message
    yield put(loginFailure('Incorrect email/password'))
  }
}

/**
 * Logout saga
 */
function* logoutSaga() {
  try {
    // Call the logout API
    yield call(logout)
    // Dispatch a success logout action
    yield put(logoutSuccess())
  } catch (error) {
    console.error(error)
  }
}

/**
 * Watching all sagas
 */
export function* watchAuth() {
  yield takeLatest(loginRequest.type, login)
  yield takeLatest(logoutRequest.type, logoutSaga)
  yield takeEvery(currentUserRequest.type, getUserByTokenSaga)
}
