/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {}

const Pricings: FC<Props> = () => {
  return (
    <div className='mt-sm-n20'>
      {/* begin::Curve top */}
      {/* <div className='landing-curve landing-dark-color'>
        <svg viewBox='15 -1 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z'
            fill='#ffc700'
          ></path>
        </svg>
      </div> */}
      {/* end::Curve top */}
      {/* begin::Wrapper */}
      <div className='py-20 bg-gray-800i'>
        {/* begin::Container */}
        <div className='container'>
          {/* begin::Plans */}
          <div className='d-flex flex-column container pt-lg-20'>
            {/* begin::Heading */}
            <div className='mb-13 text-center'>
              <h1
                className='fs-2hx fw-bolder text-white mb-5'
                id='pricing'
                data-kt-scroll-offset='{default: 100, lg: 150}'
              >
                Clear Pricing Makes it Easy
              </h1>
              <div className='text-gray-600 fw-bold fs-5'>
                Save thousands to millions of bucks by using single tool for different
                <br />
                amazing and outstanding cool and great useful admin
              </div>
            </div>
            {/* end::Heading */}
            {/* begin::Pricing */}
            <div className='text-center' id='kt_pricing'>
              {/* begin::Nav group */}
              <div
                className='nav-group landing-dark-bg d-inline-flex mb-15'
                data-kt-buttons='true'
                style={{border: '1px dashed #2B4666;'}}
              >
                <a
                  href='#'
                  className='btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active'
                  data-kt-plan='month'
                >
                  Monthly
                </a>
                <a
                  href='#'
                  className='btn btn-color-gray-600 btn-active btn-active-success px-6 py-3'
                  data-kt-plan='annual'
                >
                  Annual
                </a>
              </div>
              {/* end::Nav group */}
              {/* begin::Row */}
              <div className='row g-10'>
                {/* begin::Col */}
                <div className='col-xl-4'>
                  <div className='d-flex h-100 align-items-center'>
                    {/* begin::Option */}
                    <div className='w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10'>
                      {/* begin::Heading */}
                      <div className='mb-7 text-center'>
                        {/* begin::Title */}
                        <h1 className='text-dark mb-5 fw-boldest'>Startup</h1>
                        {/* end::Title */}
                        {/* begin::Description */}
                        <div className='text-gray-400 fw-bold mb-5'>Best Settings for Startups</div>
                        {/* end::Description */}
                        {/* begin::Price */}
                        <div className='text-center'>
                          <span className='mb-2 text-primary'>$</span>
                          <span
                            className='fs-3x fw-bolder text-primary'
                            data-kt-plan-price-month='99'
                            data-kt-plan-price-annual='999'
                          >
                            99
                          </span>
                          <span
                            className='fs-7 fw-bold opacity-50'
                            data-kt-plan-price-month='Mon'
                            data-kt-plan-price-annual='Ann'
                          >
                            / Mon
                          </span>
                        </div>
                        {/* end::Price */}
                      </div>
                      {/* end::Heading */}
                      {/* begin::Features */}
                      <div className='w-100 mb-10'>
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Up to 10 Active Users
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Up to 30 Project Integrations
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            Keen Analytics Platform
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen040.svg */}
                          <span className='svg-icon svg-icon-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <rect
                                x='7'
                                y='15.3137'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-45 7 15.3137)'
                                fill='black'
                              />
                              <rect
                                x='8.41422'
                                y='7'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(45 8.41422 7)'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800'>
                            Targets Timelines &amp; Files
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen040.svg */}
                          <span className='svg-icon svg-icon-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <rect
                                x='7'
                                y='15.3137'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-45 7 15.3137)'
                                fill='black'
                              />
                              <rect
                                x='8.41422'
                                y='7'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(45 8.41422 7)'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack'>
                          <span className='fw-bold fs-6 text-gray-800'>Unlimited Projects</span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen040.svg */}
                          <span className='svg-icon svg-icon-1'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <rect
                                x='7'
                                y='15.3137'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-45 7 15.3137)'
                                fill='black'
                              />
                              <rect
                                x='8.41422'
                                y='7'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(45 8.41422 7)'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                      </div>
                      {/* end::Features */}
                      {/* begin::Select */}
                      <a href='#' className='btn btn-primary'>
                        Select
                      </a>
                      {/* end::Select */}
                    </div>
                    {/* end::Option */}
                  </div>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-4'>
                  <div className='d-flex h-100 align-items-center'>
                    {/* begin::Option */}
                    <div className='w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10'>
                      {/* begin::Heading */}
                      <div className='mb-7 text-center'>
                        {/* begin::Title */}
                        <h1 className='text-white mb-5 fw-boldest'>Business</h1>
                        {/* end::Title */}
                        {/* begin::Description */}
                        <div className='text-white opacity-75 fw-bold mb-5'>
                          Best Settings for Business
                        </div>
                        {/* end::Description */}
                        {/* begin::Price */}
                        <div className='text-center'>
                          <span className='mb-2 text-white'>$</span>
                          <span
                            className='fs-3x fw-bolder text-white'
                            data-kt-plan-price-month='199'
                            data-kt-plan-price-annual='1999'
                          >
                            199
                          </span>
                          <span
                            className='fs-7 fw-bold text-white opacity-75'
                            data-kt-plan-price-month='Mon'
                            data-kt-plan-price-annual='Ann'
                          >
                            / Mon
                          </span>
                        </div>
                        {/* end::Price */}
                      </div>
                      {/* end::Heading */}
                      {/* begin::Features */}
                      <div className='w-100 mb-10'>
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-white opacity-75 text-start pe-3'>
                            Up to 10 Active Users
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-white opacity-75 text-start pe-3'>
                            Up to 30 Project Integrations
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-white opacity-75 text-start pe-3'>
                            Keen Analytics Platform
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-white opacity-75 text-start pe-3'>
                            Targets Timelines &amp; Files
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack'>
                          <span className='fw-bold fs-6 text-white opacity-75'>
                            Unlimited Projects
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen040.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-white'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <rect
                                x='7'
                                y='15.3137'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(-45 7 15.3137)'
                                fill='black'
                              />
                              <rect
                                x='8.41422'
                                y='7'
                                width='12'
                                height='2'
                                rx='1'
                                transform='rotate(45 8.41422 7)'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                      </div>
                      {/* end::Features */}
                      {/* begin::Select */}
                      <a
                        href='#'
                        className='btn btn-color-primary btn-active-light-primary btn-light'
                      >
                        Select
                      </a>
                      {/* end::Select */}
                    </div>
                    {/* end::Option */}
                  </div>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col-xl-4'>
                  <div className='d-flex h-100 align-items-center'>
                    {/* begin::Option */}
                    <div className='w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10'>
                      {/* begin::Heading */}
                      <div className='mb-7 text-center'>
                        {/* begin::Title */}
                        <h1 className='text-dark mb-5 fw-boldest'>Enterprise</h1>
                        {/* end::Title */}
                        {/* begin::Description */}
                        <div className='text-gray-400 fw-bold mb-5'>
                          Best Settings for Enterprise
                        </div>
                        {/* end::Description */}
                        {/* begin::Price */}
                        <div className='text-center'>
                          <span className='mb-2 text-primary'>$</span>
                          <span
                            className='fs-3x fw-bolder text-primary'
                            data-kt-plan-price-month='999'
                            data-kt-plan-price-annual='9999'
                          >
                            999
                          </span>
                          <span
                            className='fs-7 fw-bold opacity-50'
                            data-kt-plan-price-month='Mon'
                            data-kt-plan-price-annual='Ann'
                          >
                            / Mon
                          </span>
                        </div>
                        {/* end::Price */}
                      </div>
                      {/* end::Heading */}
                      {/* begin::Features */}
                      <div className='w-100 mb-10'>
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Up to 10 Active Users
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Up to 30 Project Integrations
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Keen Analytics Platform
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack mb-5'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Targets Timelines &amp; Files
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex flex-stack'>
                          <span className='fw-bold fs-6 text-gray-800 text-start pe-3'>
                            Unlimited Projects
                          </span>
                          {/* begin::Svg Icon | path: icons/duotune/general/gen043.svg */}
                          <span className='svg-icon svg-icon-1 svg-icon-success'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            >
                              <rect
                                opacity='0.3'
                                x='2'
                                y='2'
                                width='20'
                                height='20'
                                rx='10'
                                fill='black'
                              />
                              <path
                                d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                fill='black'
                              />
                            </svg>
                          </span>
                          {/* end::Svg Icon */}
                        </div>
                        {/* end::Item */}
                      </div>
                      {/* end::Features */}
                      {/* begin::Select */}
                      <a href='#' className='btn btn-primary'>
                        Select
                      </a>
                      {/* end::Select */}
                    </div>
                    {/* end::Option */}
                  </div>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
            </div>
            {/* end::Pricing */}
          </div>
          {/* end::Plans */}
        </div>
        {/* end::Container */}
      </div>
      {/* end::Wrapper */}
      {/* begin::Curve bottom */}
      {/* <div className='landing-curve landing-dark-color'>
        <svg viewBox='15 12 1470 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z'
            fill='#ffc700'
          ></path>
        </svg>
      </div> */}
      {/* end::Curve bottom */}
    </div>
  )
}

export {Pricings}
